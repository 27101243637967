import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Sidebar } from "@nulogy/components";
import { config } from "../../playgrounds/sidebar";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Sidebar`}</h1>
    <p>{`The Sidebar is designed for displaying additional information or editing/creating new items. The component provides animation, a close button, and consistent paddings and title positioining. Sidebars are always optional, and can be used on any Page you like.`}</p>
    <p>{`It is intended to be used within the `}<a parentName="p" {...{
        "href": "/application-frame"
      }}>{`ApplicationFrame`}</a>{` and along side `}<a parentName="p" {...{
        "href": "/branded-nav-bar"
      }}>{`BrandedNavBar`}</a>{`.`}</p>
    <p>{`See props for accessibility features.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/application-frame"
        }}>{`ApplicationFrame`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/page"
        }}>{`Page`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/branded-nav-bar"
        }}>{`BrandedNavBar`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      